import React from "react";
import { RxDoubleArrowDown } from "react-icons/rx";
import { Slide, Fade, JackInTheBox } from "react-awesome-reveal";
import CarouselHome from "../Components/Carousel";
import ServicesData from "../Components/ServicesData";


import icon1 from "../img/manicure.png";
import icon2 from "../img/hair.png";
import icon3 from "../img/facial.png";

import hpic from "../img/hpic.png";
import bg1 from "../img/bg.png";

import test1 from "../img/2.png";
import test2 from "../img/3.png";
import Popup from "../Components/Popup";


function importImg(r){
    return r.keys().map(r);
}


export default function Home(){

    const fnames = importImg(require.context('../img/ourimg/' , false , /\.(png|jpg|jpeg)$/));

    const Section1 = [
        {
            id: 1,
            name: 'Hair Care',
            desc: "Our hair care services offer precision cuts, rich color, and nourishing treatments to keep your hair vibrant, healthy, and beautifully styled.",
            img: icon2
        },
        {
            id: 2,
            name: 'Manicure / Pedicure',
            desc: "Enjoy stunning manicures and pedicures, featuring classic finishes or unique nail art, all designed to leave your nails healthy, polished, and beautiful.",
            img: icon1
        },
        {
            id: 3,
            name: 'Facial Care',
            desc: "Our facials rejuvenate with deep cleansing, hydration, and anti-aging techniques to give your skin a fresh, radiant, and youthful glow.",
            img: icon3
        }
    ];

    const Section3 = [
        {
            id: 1,
            name: 'Expertise and Beauty',
            desc: 'Our talented team provides top-quality hair, nail, and facial services, using professional techniques that enhance your natural beauty and well-being.'
        },
        {
            id: 2,
            name: 'Luxurious Pampering',
            desc: 'Enjoy indulgent, high-quality treatments that rejuvenate both body and mind, helping you escape from the daily stresses of life.'
        },
        {
            id: 3,
            name: 'Personalized Experience',
            desc: 'Our beauty services are customized to your unique needs in a calming, elegant atmosphere, ensuring you feel pampered, beautiful, and completely relaxed.'
        }
    ];
    
    
    function scrollSec(){
        const home1 = document.getElementById('Services');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };


    return (
        <main className="">

            <Popup />
            <section>
                <CarouselHome />
            </section>

            <section>
                <div className=" p-5 pt-6 pb-8 lg:pt-28">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-12  lg:gap-24 place-items-center lg:grid-cols-3">
                            <Fade delay={5}>
                                {Section1.map((s) => (
                                    <div key={s.id} className=" hover:opacity-50 cursor-pointer flex text-center justify-center items-center space-y-5 flex-col">
                                        <img className="w-28" src={s.img} alt={s.name} />
                                        <div className=" flex flex-col space-y-5">
                                            <div className=" space-y-2">
                                                <div>
                                                    <h2 className=" text-2xl font-Playfair text-main-color">{s.name}</h2>
                                                </div>
                                                <div>
                                                    <span className= "">
                                                        {s.desc}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fade>
                        </div>
                    </div>
                </div>
			</section>

            <section className=" bg-gradient-to-t from-red-200 to-white">
                <div className=" p-5 pt-6 pb-10 lg:pt-28">
                    <div className="mx-auto max-w-screen-xl">
                        <div className=" grid gap-4 lg:gap-6 place-items-center lg:grid-cols-2">
                            <JackInTheBox>
                                <div className="lg:h-[550px] border-main h-[320px] lg:p-4 p-2 md:block overflow-hidden">
                                    <img alt="hpic" className="ease-in-out border-main h-full transition-all duration-1000 hover:scale-125" src={hpic}/>
                                </div>
                            </JackInTheBox>
                            <div className="space-y-4 px-4 lg:px-12 lg:space-y-8 lg:py-4 justify-center text-center">
                                <div className=" text-[30px] flex flex-col items-center lg:text-4xl tracking-wide space-y-2">
                                    <div className=" border-[1px] border-main-color w-16"></div>
                                    <h2 className=" text-main-color font-Osward">
                                        About Us
                                    </h2>
                                </div>
                                <div className=" flex flex-col md:text-lg space-y-6 lg:space-y-8">
                                    <div className="space-y-4 flex flex-col">
                                        <div className=" space-x-1">
                                            <span className=" font-bold text-xl">At JN Beauty Salon,</span>
                                            <span className="">
                                                we pride ourselves on delivering exceptional beauty services in San Jose. Specializing in hair styling, nail care, and rejuvenating facials, our team of skilled professionals is dedicated to providing an experience that leaves you feeling refreshed and confident.                                            
                                            </span>
                                        </div>
                                        <span className=" hidden lg:block">
                                            We focus on quality, relaxation, and personalized care, using only top products and techniques to bring out your natural beauty. Our salon provides a welcoming and luxurious environment, ensuring that each visit is a moment of pampering and indulgence.
                                        </span>
                                        <span className="">
                                            Whether you're visiting for a trendy haircut, a stunning manicure, or a revitalizing facial, JN Beauty Salon is here to ensure you receive the highest level of care. Join us and discover why our clients trust us for all their beauty needs.
                                        </span>
                                    </div>
									<div className=" hidden lg:block">
										<button onClick={() => scrollSec()} className="group ">
											<RxDoubleArrowDown className="ease-in-out text-main-color delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main2 text-4xl" />
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Services" className="bg-white">
                <div className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24">
                    <div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
						<div className=" flex items-center space-y-3 lg:space-y-8 flex-col">
                            <div className=" text-[30px] lg:text-4xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-main-color font-Osward">
                                    Our Services
                                </h2>
                            </div>
                            <div className="text-center flex justify-center tracking-wide">
                                <div className=" lg:w-4/5">
                                    <span className=" lg:text-lg">
                                        At JN Beauty Salon, we specialize in services designed to relax, rejuvenate, and beautify. 
                                        From expert hair care to luxurious facials and manicures, our treatments are crafted to refresh both 
                                        body and mind, leaving you feeling renewed and stress-free. Experience the ultimate in self-care at 
                                        JN Beauty Salon, where enhancing your natural beauty is our passion.
                                    </span>
                                </div>
                            </div>

                            <div className="grid md:grid-cols-4 lg:mx-4 justify-items-center gap-2 cursor-pointer">
                                <Fade delay={10}>
                                    {ServicesData.map((s) => (
                                        s.id !== 6 && (
                                            <div key={s.id} className="flex flex-col cursor-pointer justify-center text-center items-center lg:mb-2 lg:py-4 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                                <div className=" p-4">
                                                    <img alt={s.name} className="h-full hover:shadow-2xl w-full border-main2" src={s.services[0].img} />
                                                </div>
                                                <div className=" font-Playfair p-4 space-y-2">
                                                    <div>
                                                        <h1 className="text-main-color text-2xl text-center">{s.name}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </Fade>
                            </div>

                            <div className=" flex justify-center">
                                <button onClick={() => window.location.href = '/Services'} className=" border-main py-3 hover:shadow-2xl transition ease-in-out duration-200 delay-100 group border-main-color cursor-pointer hover:bg-main-color px-12 border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                        View More
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-gradient-to-tl from-red-200 to-white">
                <div className=" p-5 pt-12 pb-24 lg:pt-24">
                    <div className="mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade delay={50}>
                                <div className="flex flex-col space-y-5 lg:space-y-8">
                                    <span className="text-xl lg:text-3xl font-thin">Discover Beauty & Tranquility</span>
                                    <div className="flex flex-col space-y-1 lg:space-y-2">
                                        <span className="text-3xl lg:text-4xl font-Osward text-black">
                                            Expert Beauty and Rejuvenation
                                        </span>
                                        <span className="text-4xl font-Playfair italic text-main-color">
                                            Treatments
                                        </span>
                                    </div>
                                    <span className="lg:text-lg text-black">
                                        Experience expert beauty and rejuvenating treatments at JN Beauty Salon, San Jose’s top destination for hair, nail, and facial care. Offering a peaceful and luxurious atmosphere, we ensure each visit leaves you feeling beautiful, refreshed, and renewed.
                                    </span>
                                    <div className=" ">
                                        <ul className=" space-y-8">
											{Section3.map((s) => (
												<li key = {s.id} className=" flex flex-col">
													<div className=" flex space-x-2 text-2xl">
														<span  className=" italic">0{s.id}</span>
														<span className=" font-bold italic">
															{s.name}
														</span>
													</div>
													<span className=" text-[#4A4A4A] lg:ml-8 ml-1 text-md">
														{s.desc}
													</span>
												</li>
											))}
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden border-main2 rounded-xl flex">
                                <img className="border-main2" alt="bg1" src={bg1} />
                            </div>
                            <div className="hidden border-main2 hover:opacity-50 cursor-pointer lg:block lg:bg-bg4 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" lg:h-[600px]">
                <div className=" h-full grid lg:grid-cols-2">
                    <Fade><img alt="test1" className=" h-[300px] lg:h-full cursor-pointer hover:opacity-80" src={test1} /></Fade>
                    <Fade><img alt="test2" className="h-[300px] lg:h-full cursor-pointer hover:opacity-80" src={test2} /></Fade>
                </div>
            </section>

            <section id="Gallery" className="bg-white">
                <div className=" p-5 pt-12 pb-8 lg:pt-24 lg:pb-24">
                    <div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
						<div className=" flex items-center space-y-3 lg:space-y-8 flex-col">
                            <div className=" text-[30px] lg:text-4xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-main-color font-Osward">
                                    Our Gallery
                                </h2>
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 justify-items-center p-4 cursor-pointer">
                            {fnames.slice(0,6).map((fname , index) =>(
                                <div key={index} className="shadow-xl overflow-hidden rounded-lg">
                                    <img className="w-full ease-in-out transition-all duration-1000 hover:scale-125" src={fname} alt={fname}/>
                                </div>
                            ))}
                        </div>

                        <div className=" flex justify-center">
                            <button onClick={() => window.location.href = '/Gallery'} className=" border-main2 py-3 transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color px-12 border-[1px]">
                                <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                    View More
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

			<section id="Contact" className=" lg:bg-bg7 lg:bg-cover lg:bg-center lg:bg-fixed lg:bg-no-repeat">
                <div className="pt-6 h-full pb-6 space-y-4">
                    <div className="lg:p-4 h-full p-2">
                        <div className=" grid h-full lg:grid-cols-2 gap-8 lg:gap-12">
                            <div className="">
                                <iframe className=" w-full h-[500px] lg:h-full" id="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.2897637334854!2d-121.89052082424698!3d37.406625433281825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcf99c6131a15%3A0x54a352babe4814b1!2sJN%20Beauty%20Salon!5e0!3m2!1sen!2sus!4v1730678626203!5m2!1sen!2sus" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </div>
                            <Slide delay={4} direction="down">
                                <div className=" lg:py-24 mx-2 lg:mx-0 flex flex-col space-y-4">
                                    <div className=" text-[30px] lg:text-[44px] flex flex-col tracking-wide space-y-[1px]">
                                        <div className=" border-[1px] border-main-color w-16"></div>
                                        <h2 className=" font-Osward text-main-color">
                                            Contact Us
                                        </h2>
                                    </div>
                                    <div className=" max-w-2xl flex flex-col space-y-5 lg:space-y-6">
                                        <div>
                                            <span>
                                                Conveniently located in the heart of San Jose, JN Beauty Salon offers a luxurious escape with a relaxing atmosphere and private treatment rooms for a serene beauty experience. Our skilled team provides personalized hair, nail, and facial services to help you look and feel your best. Proudly serving San Jose, Santa Clara, Sunnyvale, Milpitas, Campbell, Cupertino, Mountain View, and surrounding communities.                                            
                                            </span>
                                        </div>
                                        <div>
                                            <ul className="text-xl space-y-1">
                                                <li className=" text-main-color font-Osward lg:text-2xl">JN Beauty Salon</li>
                                                <li>2095 N Capitol Ave, Ste A</li>
                                                <li>San Jose, CA 95132</li>
                                            </ul>
                                        </div>
                                        <div className="flex font-bold space-y-2 flex-col lg:text-xl">
                                            <span>
                                                Open From: 9:30 AM to 7:00 PM
                                            </span>
                                            <span>
                                                Open Days: Monday to Saturday
                                            </span>
                                            <span>
                                                10:00 AM to 6:00 PM on Sunday
                                            </span>
                                        </div>
                                        <div className="">
                                        <button onClick={() => {
                                            window.location.href = "tel:6699777799";
                                        }} className=" mt-4 hover:shadow-lg flex items-center justify-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                                <span>Book Now</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </section>


        </main>
    )
}