import React , {useState} from "react";
import ServicesData from "../Components/ServicesData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark , faPlus} from "@fortawesome/free-solid-svg-icons";
import {Fade,Slide} from "react-awesome-reveal";

export default function Services(){

    const [IsClick , setIsClick] = useState(false);
    const handleBtn = (id) => {
        setIsClick((lastClick) => ({
            [id]: !lastClick[id],
        }));
    };

    return (
        <section className = "space-y-2 lg:pb-12 ">
            <div className="bg-bg6 img-test bg-no-repeat bg-center bg-cover h-[300px] lg:h-[580px] relative">
                <div className="h-[300px] lg:h-[580px] relative">
                    <div className="font-Playfair w-full absolute -top-12 text-3xl h-full flex items-end justify-center cursor-pointer text-white lg:text-5xl">
                        <h2 className="">Services & Pricing</h2>
                    </div>
                </div>
            </div>
            <div className="lg:p-4 p-2 mx-auto max-w-screen-2xl">
                <div className=" flex flex-col lg:space-y-14">
                    {/* Mobile View */}
                    <div className="p-2 mt-5 space-y-5 md:hidden">
                        {ServicesData.map((s) => (
                            <div className="grid grid-cols-1 overflow-auto">
                                <div className="space-y-2 flex flex-col items-center justify-center">
                                    <div className="w-[90%] py-5 shadow-lg border-main bg-main-color/60 text-white font-semibold tracking-wide uppercase text-center" key={s.id} onClick={() => handleBtn(s.id)}>
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-2 italic tracking-wider">{s.name}</div>
                                            <div>
                                                {!IsClick[s.id]?
                                                    <FontAwesomeIcon className="" icon={faPlus} />: <FontAwesomeIcon className="text-white" icon={faXmark} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {IsClick[s.id] && (
                                        <Fade duration={1500} delay={100}>
                                            <div className="">
                                                <div className="p-2 shadow-md space-y-4 rounded-lg">
                                                    <div className="">
                                                        <img src={s.services[0].img} alt = {s.name} />
                                                    </div>
                                                    {s.services.map((se , index) => (
                                                        <>
                                                            <div className="text-black flex flex-col gap-4">
                                                                <div className="flex font-Playfair flex-col space-y-2">
                                                                    <span className="text-xl">{index + 1}. {se.name}</span>
                                                                    {s.desc && (
                                                                        <div className=" flex flex-col space-y-2">
                                                                            <span className=" text-justify">{se.desc}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className=" flex justify-center items-center">
                                                                    <span className=" text-main-color tracking-wider font-bold">{se.price}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>  
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </Fade>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                                                        
                    {/* Desktop View */}
                    <Slide duration={1500} delay={100}>
                        {ServicesData.map((ser) => (
                        <div className="p-3 space-y-8 hidden md:block">
                                <div id={ser.id} key={ser.id} className="flex justify-center">
                                    <div className="bg-main-color/80 rounded-full shadow-md  px-12 py-3">
                                        <h1 className="tracking-wide capitalize cursor-pointer italic text-white md:text-4xl font-bold">{ser.name}</h1>
                                    </div>
                                </div>
                                <div className="p-4 cursor-pointer">
                                    <div className=" grid gap-4 grid-cols-4">
                                        {ser.services.map((s , index) => (
                                            <div key={index} className="shadow-lg h-fit rounded-lg mb-2 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                                {(ser.id === 1 || ser.id === 2 || ser.id === 5 || ser.id === 4 )&& (
                                                    <div className="overflow-hidden">
                                                        <img alt={s.name} src={s.img} />
                                                    </div>
                                                )}
                                                <div className="px-8 space-y-4 mb-4 mt-4">
                                                    <h1 className="md:text-lg text-center capitalize border-main-color/40 border-b-2 font-bold py-1">{s.name}</h1>
                                                    {s.desc&&(
                                                        <div className=" text-black text-justify">
                                                            <ul className=" list-inside space-y-2 list-disc">
                                                                <li>
                                                                    {s.desc}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    
                                                    <div className="text-xl text-main-color tracking-wide text-center">
                                                        {s.price}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        </div>
                        ))}
                    </Slide>
                </div>
            </div>
        </section>
    );
};