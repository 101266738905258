import {React} from "react"
import Logo from "../img/Logo.png";
import ServicesData from "./ServicesData";

export default function Footer(){
    return(
        <footer className="bg-white cursor-pointer">
            <div className="mx-auto max-w-screen-xl px-6 pb-6 pt-16 lg:px-8 lg:pt-24">
                <div className="grid lg:space-x-12 grid-cols-1 gap-12 lg:grid-cols-3">
                    <div className="grid justify-items-center">
                        <div className="">
                            <img alt ="#Logo" className="w-24 md:flex" src={Logo} />
                        </div> 
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">About Us</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a href="/" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a  href="/Services" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a  href="/Gallery" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Gallery
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Services</p> 
                            <ul className="mt-8 space-y-4 text-sm">
                                {ServicesData.map((d) => (
                                    <li key={d.id}>
                                        <a target="_blank" href="/Services" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                            {d.name}
                                        </a>
                                </li>
                                ))}
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Business Hours</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Mon: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Tues: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Wed: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Thu: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Fri: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Sat: 9:30 am - 7:00 pm
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Sun: 10:00 am - 6:00 pm
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color font-Playfair">Contact Us</p>
                            <ul className="mt-8 space-y-5 text-sm">
                                {/* <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="/">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 shrink-0 text-main-color font-Playfair"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <a href="mailto:mspabeautynails@gmail.com" className="flex-1 text-gray-700">mspabeautynails@gmail.com</a>
                                    </a>
                                </li> */}

                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:6699777799">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 shrink-0 text-main-color font-Playfair"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>

                                        <span className="flex-1 text-gray-700">(669) 977-7799</span>
                                    </a>
                                </li>

                                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 shrink-0 text-main-color font-Playfair"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    </svg>
                                    <a  target="_blank"  rel="noreferrer" href = "https://maps.app.goo.gl/obuAEZXSNXTfrExh8" className="-mt-0.5 flex-1 not-italic text-gray-700">
                                        2095 N Capitol Ave, Ste A, San Jose, CA 95132
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-12 border-t border-gray-100 pt-6">
                    <div className="text-center sm:flex sm:justify-between sm:text-left">
                        <p className="text-sm text-gray-500">
                            <span className="block sm:inline">All rights reserved.</span>
                        </p>  
        
                        <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                            &copy; 2024 JN Beauty Salon
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};